<template>
  <div
    class="evo-decorate-rect"
    :class="{
      'evo-diamond-yellow': color === 'yellow',
      'evo-diamond-blue': color === 'blue',
      'evo-px-decorate-rect': $vuetify.breakpoint.xsOnly,
    }"
  ></div>
</template>

<script>
export default {
  name: "EvoDiamond",
  props: {
    color: {
      type: String,
      default: "yellow",
    },
  },
};
</script>

<style lang="scss" scoped>
.evo-decorate-rect {
  width: 21px;
  height: 21px;
  background: linear-gradient(123deg, #ffcf54 0%, #ff6d44 100%);
  border-radius: 3px;
  transform: rotate(45deg);
}

.evo-px-decorate-rect {
  width: 21px;
  height: 21px;
}

.evo-diamond-yellow {
  background: linear-gradient(123deg, #ffcf54 0%, #ff6d44 100%);
}

.evo-diamond-blue {
  background: #2161ff;
}
</style>
