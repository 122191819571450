<template>
  <div
    class="evo-rect"
    :class="{
      'baby-blue': color === 'babyBlue',
      'sky-blue': color === 'skyBlue',
      'gradient-blue': color === 'gradientBlue',
    }"
  ></div>
</template>

<script>
export default {
  name: "EvoRect",
  props: {
    color: {
      type: String,
      default: "babyBlue",
    },
  },
};
</script>

<style lang="scss" scoped>
.evo-rect {
  width: 100%;
  height: 30px;
  &.baby-blue {
    background: linear-gradient(
      211deg,
      #56d7ff 0%,
      rgba(117, 219, 255, 0.51) 100%
    );
  }

  &.sky-blue {
    background: #2161ff;
  }

  &.gradient-blue {
    background: linear-gradient(
      312deg,
      rgba(92, 180, 255, 0.72) 0%,
      #2161ff 100%
    );
  }
}
</style>
